@import '../styles/variables';
@import '../styles/mixins';

@mixin primaryAccountButton {
  @include button($mostly-pure-yellow, $navy-blue, $white, $navy-blue);

  font-family: $font-family-strong;
  font-size: $font-size-medium;

  min-width: 100%;

  &:disabled {
    background-color: $lightgrey;
    color: $greyish-orange;
  }
}
