@import '../../styles/variables.scss';
@import '../../styles/spaced';
@import '../mixins';
@import '../../styles/breakpoints.scss';

$input-width: 17rem;

.accountForm {
  max-width: $input-width;
  width: 100%;

  @include breakpoint(screen-md, max) {
    max-width: 100%;
  }

  .label {
    font-family: $font-family-regular;
    font-size: $font-size-medium;
    padding: 1em 0 0.5em;
  }

  input {
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    box-sizing: border-box;
    padding: 0.75em 1em;
    border-radius: 1px;
    border: solid 1px $cloud-grey;
    color: $navy-blue;
    background-color: $white;

    &:not[type='radio'] {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &.readOnly {
      color: $greyish-orange;
      outline: none;
    }
  }

  .checkbox {
    @include spaced-horizontal(1rem);
  }

  .submitButton {
    @include primaryAccountButton();
    box-sizing: border-box;
    text-align: center;
    font-family: $font-family-strong;
    width: 100%;
    margin-top: 3rem;
    &:disabled {
      background-color: $lightgrey;
      color: $darkgrey;
    }
  }

  .inputField {
    display: flex;
    flex-direction: column;
  }

  .formError {
    $warning-color: #db392a;
    input {
      color: $warning-color;
      border-color: $warning-color;
    }
  }
}

.section {
  margin-top: 2rem;

  .header {
    font-size: $font-size-medium;
    font-family: $font-family-strong;
    margin: 0 0 0.5rem;
  }

  > label {
    font-family: $font-family-regular;
    font-size: $font-size-medium;
    padding: 0.5em 0;
  }
}

.deleteShowCaseAccount {
  margin-top: 2rem;
  display: inline-block;
  font-family: $font-family-regular;
  font-size: $font-size-medium;
  color: $cerulean;
}
