@import '../../styles/variables.scss';

.dropdown {
  position: relative;
}

.input {
  font-size: $font-size-medium;
  font-family: $font-family-regular;
  box-sizing: border-box;
  padding: 0.75em 1em;
  border-radius: 1px;
  border: solid 1px $cloud-grey;
  color: $navy-blue;
  background-color: $white;

  &:not[type='radio'] {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &.readOnly {
    color: $greyish-orange;
    outline: none;
  }
  &.error {
    border-color: $warning-color;
  }
}
