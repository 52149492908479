@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/spaced.scss';

.form {
  width: 100%;
  max-width: 20rem;
  font-family: $font-family-regular;

  @include breakpoint(screen-md, max) {
    max-width: 100%;
  }

  .inputField {
    display: flex;
    flex-direction: column;
    padding-top: 1em;

    .label {
      font-family: $font-family-regular;
      font-size: $font-size-medium;
      padding: 0 0 0.5em;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: 3rem;
  }
}

.portsDropdown {
  margin-bottom: 2rem;
  width: 100%;

  input {
    width: 100%;
    max-width: 100%;
  }
}

.checkbox {
  @include spaced-horizontal(1rem);
}
