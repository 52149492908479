@import '../../styles/variables.scss';
@import '../../styles/z-indices.scss';
@import '../../styles/breakpoints.scss';
@import '../../Account/mixins.scss';

$content-width-large: 52.5rem;
$content-width-medium: 28.125rem;

.signedUpWithEmail {
  color: $navy-blue;
  width: 100%;
  max-width: $content-width-large;
  font-size: $font-size-medium;

  @include breakpoint(screen-md, max) {
    max-width: 100%;
  }

  .title {
    .icon {
      color: $moderate-lime-green;
      padding-right: 0.5rem;
      transform: translateY(-1px);
    }
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .importantCaption {
    padding-top: 1em;
    color: $warning-color;
  }

  .formContainer {
    max-width: 20rem;
  }

  .switchState {
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    white-space: pre-wrap;
    a {
      font-family: $font-family-strong;
      color: $cerulean;
    }
  }
}
