@import '../styles/variables.scss';
@import '../styles/z-indices.scss';
@import '../styles/breakpoints.scss';
@import './variables.scss';
@import './mixins';

.accountMenuWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: $menu-width;
  background-color: $grape-color;
  color: $white;
  font-family: $font-family-regular;
  min-height: 100vh;

  @include breakpoint(screen-lg, max) {
    z-index: $accountMenuIndex;
    width: 100%;
    min-height: auto;
    padding: 0;
    > h1 {
      display: none;
    }
  }
}

.accountHeader {
  box-sizing: border-box;
  height: $header-height;
  display: flex;
  align-items: center;
  padding-left: $menu-padding-left;
  padding-right: $menu-padding-right;

  @include breakpoint(screen-lg, max) {
    padding: 0;
    height: $header-height-screen-lg;
  }
}

.accountLogo {
  fill: $white;
  padding-left: 1em;
}

.accountHeaderItem {
  min-height: 100%;
  display: flex;
  align-items: center;

  @include breakpoint(screen-lg, max) {
    border-left: 1px rgba($white, 0.5) solid;
    padding: 0 1em 0 0.5em;
  }

  &:first-child {
    @include breakpoint(screen-lg, min) {
      display: none;
    }
    border-left: none;
    padding-left: 1em;
  }
}
