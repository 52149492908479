@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';
@import '../variables.scss';

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @include breakpoint(screen-lg, max) {
    $header-height: $header-height-screen-lg;
    height: unset;
    width: $menu-width;

    @include breakpoint(screen-sm, max) {
      width: 100%;
    }
    min-height: calc(100% - #{$header-height});
    transform: translateX(-100%);
    position: absolute;
    background-color: $grape-color;
    left: 0;
    top: $header-height;

    &.open {
      transform: translateX(0);
      box-shadow: 2px 1px 4px 0 rgba($darkgrey, 0.5);
    }

    &.open,
    &.closed {
      transition: transform 0.3s ease-out;
    }
  }

  .navItem {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: $white;
    padding: 1.25rem 0;
    width: 100%;
    text-decoration: none;
    font-family: $font-family-regular;
    font-size: $font-size-large;
    padding-left: $menu-padding-left;
    padding-right: $menu-padding-right;

    &:not(.disabled):hover {
      cursor: pointer;
      text-decoration: underline rgba($white, 0.7);
    }

    &.disabled {
      pointer-events: none;

      .navIcon,
      .text {
        opacity: 0.2;
      }
    }
  }

  .navIcon {
    opacity: 0.3;
    margin-right: 10px;
    path {
      fill: $white;
    }
  }

  .navPrimary {
    .navItem {
      border-top: 1px solid rgba($darkgrey, 0.3);

      &:last-child:not(.navItemActive) {
        border-bottom: 1px solid rgba($darkgrey, 0.3);
      }
    }
    .navItemActive {
      background-color: rgba($white, 0.15);
      border-color: rgba($darkgrey, 0);

      + .navItem {
        border-color: rgba($darkgrey, 0);
      }

      .navIcon {
        opacity: 1;
      }
    }
  }
  .navSecondary {
    margin-bottom: 1.75rem;
    .navItem {
      padding: 0.75rem 2.5rem;
    }
  }
}
