@import '../styles/variables.scss';
@import '../styles/breakpoints.scss';
@import '../styles/mixins.scss';
@import '../Account/variables';

.genericPage {
  $contentWidth: 840px;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--primary-color);
  color: var(--text-color);
  .scrollable {
    flex: 1 1 auto;
    width: 100%;
    overflow-y: scroll;
  }

  .content {
    width: $contentWidth;
    max-width: 100%;
    padding: 3rem 1rem;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: var(--primary-color);

    &.formatTags {
      h2 {
        $font-size: 3em;
      }
      a {
        color: $cerulean;
      }
      p {
        white-space: pre-wrap;
        font-family: $font-family-regular;

        strong {
          font-family: $font-family-strong;

          a {
            font-family: $font-family-strong;
          }
        }
      }
    }
  }

  .portsOverview {
    column-gap: 0.75rem;
    column-count: 3;
    padding-bottom: 2rem;

    @include breakpoint(screen-sm, max) {
      column-count: 2;
    }

    .section {
      & + .section {
        padding-top: 1rem;
      }
    }
    .port {
      color: var(--text-color);
      text-decoration: none;
      font-size: $font-size-large;
      font-family: $font-family-regular;
      appearance: none;
      outline: none;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;

      &Disabled {
        pointer-events: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .country {
      .flag {
        height: 1.125rem;
        vertical-align: text-bottom;
      }

      .name {
        padding-left: 0.25rem;
        text-transform: uppercase;
      }
    }
  }

  .createAccount {
    padding-bottom: 2rem;

    .cta {
      padding: 1rem 0;
      width: $input-width;
    }
  }
}
.apiCreditBundles {
  font-size: $font-size-medium;

  .bundles {
    padding: 3rem 0;
  }

  .faq {
    color: var(--text-color);
    padding: 2rem 0;
  }

  .cta {
    @include inShipTrackerDarkMode {
      background-color: rgba($white, 0.1);
    }
    color: var(--text-color);
  }

  .selectBundles {
    font-size: $font-size-18;
  }

  .comparePlans {
    padding: 1rem 0;
    display: grid;
    column-gap: 2em;
    row-gap: 2em;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint(screen-lg, max) {
      column-gap: 1em;
      row-gap: 1em;
    }

    @include breakpoint(screen-sm, max) {
      grid-template-columns: 1fr;
    }
  }
}
