@import '../styles/variables.scss';
@import '../styles/z-indices.scss';
@import '../styles/breakpoints.scss';
@import '../Account/mixins.scss';

$max-content-width: 38.3125rem;

.stepHeader {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 1.25rem 0;
  margin: 0 0 2.5rem;
  color: $navy-blue;

  &Prefix {
    font-family: $font-family-regular;
  }

  &ButtonGroup {
    border-radius: 6px;
    display: flex;
    align-items: center;
    background-color: $cerulean;
    margin-left: auto;
  }

  &CloseButton {
    @include clickable;
    display: flex;
    align-items: center;
    color: $white;

    &Icon {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  &BackButton {
    $muted-white: rgba($white, 0.5);
    display: flex;
    align-items: center;
    font-family: $font-family-strong;
    font-size: $font-size-medium;
    color: $white;
    appearance: none;
    cursor: pointer;
    border-right: 1px $muted-white solid;
    text-transform: lowercase;
    line-height: 2;

    &Disabled {
      color: $muted-white;
      pointer-events: none;
    }

    .backIcon {
      transform: translateY(-1px);
    }
  }
}

.stepSubHeader {
  margin: 0 0 0.75rem;
  font-size: $font-size-20;
  color: $navy-blue;
}

.stepTitle {
  max-width: $max-content-width;
  color: $navy-blue;
  font-family: $font-family-strong;
  font-size: $font-size-extra-large;
  margin: 0;
  padding-bottom: 0.83em;
  white-space: pre-wrap;

  @include breakpoint(screen-md, max) {
    font-size: $font-size-18;
  }
}

.text {
  max-width: $max-content-width;
  margin: 0 0 1.5rem;
  font-size: $font-size-large;
  line-height: 18px;
  font-family: $font-family-regular;
  color: $navy-blue;
  white-space: pre-wrap;
}

.stepFooter {
  display: flex;
  align-items: center;
  padding-top: 2rem;

  & > * {
    &:not(:first-child) {
      margin-left: 2rem;
    }
  }
}

.link {
  display: block;
  margin: 1.5rem 0 2.5rem;
  font-size: $font-size-large;
  font-family: $font-family-regular;
  color: $cerulean;
  text-decoration: underline;
}
