@import '../../styles/variables.scss';

.inputError {
  margin-top: 5px;
  font-family: $font-family-regular;
  font-size: $font-size-regular;

  .note {
    color: $warning-color;
  }
  .message {
    text-transform: lowercase;
  }
}
