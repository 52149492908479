@import '../../styles/variables.scss';
@import '../../styles/z-indices.scss';
@import '../../styles/breakpoints.scss';
@import '../../Account/mixins.scss';

.authenticationStep {
  $input-width: 17.3125rem;

  max-width: 20rem;

  @include breakpoint(screen-md, max) {
    max-width: 100%;
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .submitButton {
    margin-top: 2rem;
  }

  .seperator {
    margin: 2rem 0;
    display: flex;
    align-items: center;

    &:before,
    &:after {
      content: '';
      display: block;
      flex-grow: 1;
      border-bottom: solid 1px $cloud-grey;
    }

    .choose {
      padding: 0 0.5rem;
      font-size: $font-size-medium;
      font-family: $font-family-regular;
    }
  }

  .ssoButton {
    background-color: $white;
    color: $navy-blue;
    border: solid 1px $cloud-grey;
    display: flex;
    align-items: center;

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      background-position: left center;
      background-repeat: no-repeat;
      padding-right: 0.25rem;
    }
  }

  .switchState {
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    white-space: pre-wrap;
    a {
      font-family: $font-family-strong;
      color: $cerulean;
    }
  }

  .passwordChecks {
    padding: 0.75em 0;

    .rules {
      box-sizing: border-box;
      font-family: $font-family-regular;
      font-size: $font-size-medium;
      list-style: none;
      padding: 0;
      margin: 0;

      .rules {
        margin-top: 0.5rem;
        padding-left: 1rem;
      }

      .rule + .rule {
        margin-top: 0.5rem;
      }

      .entry {
        display: flex;
        align-items: center;
      }

      .check {
        font-size: $font-size-medium;
        color: $dark-greyish-orange;
        padding-right: 0.25rem;

        &.isChecked {
          color: $moderate-lime-green;
        }
      }
    }
  }

  .errorBox {
    box-sizing: border-box;
    position: relative;
    color: $white;
    display: flex;
    padding: 1rem 2rem 1rem 0.75rem;
    font-size: $font-size-medium;
    border-radius: 3px;
    background-color: $warning-color;

    .closeButton {
      @include clickable();
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: $accountMenuIndex;
    }

    .closeButtonIcon {
      color: $white;
      font-size: $font-size-large;
      font-weight: bold;
    }

    &Content {
      display: flex;
      flex-direction: column;

      .description {
        margin: 0;
        font-family: $font-family-regular;
      }
    }
  }
}
