@import '../../styles/variables.scss';

.legalTerms {
  font-size: $font-size-medium;
  padding-top: 1rem;
}

.combinedTermsTitle {
  color: $cerulean;
  font-size: $font-size-extra-large;
  margin-top: 4rem;
  margin-bottom: 1rem;
}
