@import '../styles/variables.scss';
@import '../styles/breakpoints.scss';
@import '../styles/z-indices.scss';
@import './mixins';
@import './variables';

$base-padding: 4.0625rem;

.accountPage {
  position: relative;
  display: flex;
  background-color: $white;
  color: $navy-blue;

  @include breakpoint(screen-lg, max) {
    flex-direction: column;
    min-height: 100vh;
  }
}

.accountMenuTitle {
  padding-left: $menu-padding-left;
  padding-right: $menu-padding-right;
}

.closeButton {
  @include clickable();
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: $accountMenuIndex;

  @include breakpoint(screen-lg, max) {
    color: $white;
  }
}

.closeButtonIcon {
  font-size: 25px;
  font-weight: bold;
}

.account {
  &Form {
    &Title {
      font-size: $font-size-extra-extra-large;
      font-family: $font-family-strong;
      margin: 0 0 1.75rem;
      &.isPageTitle {
        padding-top: $base-padding;
      }
    }

    &Entry {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      font-family: $font-family-regular;

      &Label {
        padding-bottom: 5px;
        font-size: $font-size-medium;
      }
    }

    .label {
      font-family: $font-family-regular;
      font-size: $font-size-medium;
      padding-bottom: 5px;
    }

    input {
      font-size: $font-size-medium;
      font-family: $font-family-regular;
      box-sizing: border-box;
      padding: 0.75em 1em;
      border-radius: 1px;
      border: solid 1px $cloud-grey;
      background-color: $white;
      max-width: $input-width;
      &:focus {
        outline: none;
      }
    }

    &Error {
      $warning-color: #db392a;
      input {
        color: $warning-color;
        border-color: $warning-color;
      }

      &Notification {
        margin-top: 5px;
        font-family: $font-family-regular;
        font-size: $font-size-regular;

        .note {
          color: $warning-color;
        }
        .message {
          text-transform: lowercase;
        }
      }
    }
  }

  &MainContent {
    box-sizing: border-box;
    padding: 0 $base-padding $base-padding;
    width: 955px;
    max-width: 100%;
  }
}

.submitButton {
  @include primaryAccountButton();

  &:disabled {
    background-color: $lightgrey;
    color: $darkgrey;
  }
}

.overlay {
  z-index: $accountMenuOverlayIndex;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $shadow-color;
}
