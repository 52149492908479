@import '../../styles/variables.scss';
@import '../../styles/z-indices.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';

.loginPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100%;
  background-color: var(--primary-color);
  overflow-y: auto;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 3.5rem;
    box-sizing: border-box;

    @include breakpoint(screen-sm, max) {
      position: sticky;
      background-color: var(--primary-color);
      padding-right: 0.25rem;
      padding-left: 0.5rem;
    }

    .logo {
      fill: var(--text-color);
    }

    .closeButton {
      @include clickable;
    }

    .closeButtonIcon {
      font-size: 1.5rem;
      font-weight: bold;
      color: var(--text-color);
    }
  }

  .contentWrapper {
    display: flex;
    height: 50rem;
    max-height: 100%;
    padding: 0 1rem;

    .content {
      width: 25rem;
      max-width: 100%;
      padding: 2rem 0;
      margin-top: auto;
      margin-right: auto;
      margin-left: auto;

      &Centered {
        margin-bottom: auto;
      }

      .title {
        margin: 0 0 0.5rem;
        font-size: $font-size-extra-large;
        font-family: $font-family-strong;
        color: var(--text-color);

        @include breakpoint(screen-md, max) {
          font-size: $font-size-18;
        }
      }

      .text {
        margin: 0 0 1.5rem;
        font-size: $font-size-large;
        font-family: $font-family-regular;
        color: var(--text-color);
      }

      .inputField {
        color: var(--text-color);
      }

      .switchState {
        font-size: $font-size-medium;
        font-family: $font-family-regular;
        color: var(--text-color);

        a {
          font-family: $font-family-strong;
          color: var(--accent-color);
        }
      }

      .spinnerContainer {
        color: var(--text-color);

        .spinner {
          @include spinner(10px, 0, var(--border-color), var(--text-color));
        }
      }

      .seperator {
        margin: 2rem 0;
        display: flex;
        align-items: center;

        &:before,
        &:after {
          content: '';
          display: block;
          flex-grow: 1;
          border-bottom: solid 1px $cloud-grey;
        }

        .choose {
          padding: 0 0.5rem;
          font-size: $font-size-medium;
          font-family: $font-family-regular;
          color: var(--text-color);
        }
      }
    }
  }
}
