@import '../styles/variables.scss';
@import '../styles/z-indices.scss';
@import '../styles/breakpoints.scss';
@import '../Account/mixins.scss';

$menu-width: 335px;

.flowContainer {
  display: flex;
  width: 100%;
  min-height: 100%;

  @include breakpoint(screen-md, max) {
    flex-direction: column;
    height: 100vh;
  }

  .mobileTopBar {
    display: flex;
    height: 4.375rem;
    background-color: $navy-blue;
    align-items: center;
    padding: 0 1rem;
    flex-shrink: 0;

    .logoContainer {
      transform: translate(-0.5rem, 1px) scale(0.95);

      .logo {
        fill: $white;
      }
    }

    .title {
      margin-left: auto;
      font-size: $font-size-medium;
      font-family: $font-family-strong;
      color: $white;
    }
  }

  .mobileFooter {
    background-color: $navy-blue;
    padding: 1rem;

    .title {
      margin: 0 0 1rem;
      font-size: $font-size-medium;
      font-family: $font-family-strong;
      color: $white;
    }
    .stepsList {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      align-items: center;
      justify-content: center;

      .stepIndicator {
        flex: 0 0 auto;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 1.125rem;
        height: 1.125rem;
        border: 2px solid $blue-bayoux;
        border-radius: 50%;
        font-size: $font-size-medium;
        color: $blue-bayoux;
        z-index: 1;

        &Active {
          background-color: $white;
          border-color: $white;
          color: $navy-blue;
        }
      }

      .stepDivider {
        flex: 1 1 auto;
        margin: auto 4px;
        height: 2px;
        background: $blue-bayoux;
        z-index: 0;
      }
    }
  }

  .menuDrawer {
    width: $menu-width;
    background-color: $navy-blue;
    flex-shrink: 0;

    .header {
      padding: 1rem 2rem;

      .logoContainer {
        transform: translateX(-0.5rem);

        .logo {
          fill: $white;
        }
      }

      .title {
        font-size: $font-size-extra-extra-large;
        line-height: 1.15;
        font-family: $font-family-strong;
        color: $white;
      }
    }

    .stepsList {
      margin: 0;
      padding: 0;
      list-style: none;

      .stepItem {
        border-top: 1px solid $blue-bayoux;
        padding: 1rem 2rem;
        color: $alto;
        font-size: $font-size-medium;
        font-family: $font-family-regular;

        &:last-child {
          border-bottom: 1px solid $blue-bayoux;
        }

        .stepIndicator {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 1.125rem;
          height: 1.125rem;
          margin-right: 0.5rem;
          border: 2px solid $blue-bayoux;
          border-radius: 50%;
          font-size: $font-size-medium;
          font-family: $font-family-strong;
          color: $blue-bayoux;
        }

        &Active {
          background-color: $blue-bayoux;

          .stepIndicator {
            background-color: $white;
            border-color: $white;
            color: $navy-blue;
          }
        }
      }
    }
  }
  .content {
    flex: 1 1 auto;
    padding: 0 4rem 6rem;

    @include breakpoint(screen-md, max) {
      flex: 1 1 auto;
      padding: 1rem;
      overflow-y: scroll;
    }
  }
}
