@import '../../styles/variables.scss';
@import '../../styles/z-indices.scss';
@import '../../styles/breakpoints.scss';
@import '../../Account/mixins.scss';

.form {
  width: 100%;

  @include breakpoint(screen-md, max) {
    max-width: 100%;
  }

  input {
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    box-sizing: border-box;
    padding: 0.75em 1em;
    border-radius: 1px;
    border: solid 1px $cloud-grey;
    color: $navy-blue;
    background-color: $white;

    &:not[type='radio'] {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &.readOnly {
      color: $greyish-orange;
      outline: none;
    }
    &.error {
      border-color: $warning-color;
    }
  }

  .label {
    font-family: $font-family-regular;
    font-size: $font-size-medium;
    padding: 1em 0 0.5em;
  }

  .inputField {
    display: flex;
    flex-direction: column;
  }

  .submitButton {
    width: 100%;
    margin-top: 2rem;
  }
}

.messageBox {
  box-sizing: border-box;
  position: relative;
  color: $white;
  display: flex;
  padding: 1rem 2rem 1rem 0.75rem;
  font-size: $font-size-medium;
  border-radius: 3px;

  &.error {
    background-color: $warning-color;
  }

  &.success {
    background-color: $moderate-lime-green;
  }
  &.warning {
    color: darken($mostly-pure-yellow, 10%);
    background-color: rgba($mostly-pure-yellow, 0.3);
    border: solid 1px darken($mostly-pure-yellow, 20%);

    a {
      color: inherit;
    }
  }

  .closeButton {
    @include clickable();
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: $accountMenuIndex;
  }

  .closeButtonIcon {
    color: $white;
    font-size: 16px;
    font-weight: bold;
  }

  &Content {
    display: flex;
    flex-direction: column;

    .description {
      margin: 0;
      font-family: $font-family-regular;
    }
  }
}

.section {
  .ssoButton {
    width: 100%;
    background-color: $white;
    color: $navy-blue;
    border: solid 1px $cloud-grey;
    display: flex;
    align-items: center;

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      background-position: left center;
      background-image: url(icons/google.svg);
      background-repeat: no-repeat;
      padding-right: 0.25rem;
    }
  }
}

.passwordChecks {
  padding: 0.75em 0;

  .passwordChecksTitle {
    margin-bottom: 0.5rem;
  }

  .rules {
    box-sizing: border-box;
    font-family: $font-family-regular;
    font-size: $font-size-medium;
    line-height: $font-size-medium + 6px;
    list-style: none;
    padding: 0;
    margin: 0;

    .rules {
      margin-top: 0.5rem;
      padding-left: 1rem;
    }

    .rule + .rule {
      margin-top: 0.5rem;
    }

    .entry {
      display: flex;
      align-items: flex-start;
    }

    .check {
      font-size: $font-size-medium;
      color: $dark-greyish-orange;
      padding-right: 0.35rem;
      transform: translateY(2.5px);

      &.isChecked {
        color: $moderate-lime-green;
      }
    }
  }
}
